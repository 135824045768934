/**=====================
     39. Table  CSS Start
==========================**/
thead, tbody, tfoot, tr, td, th {
	border-color: #dee2e6;
}
.table{
	margin-bottom: 0;
	> :not(:last-child) {
		> :last-child {
			> * {
				border-bottom-color: #dee2e6;
			}
		}
	}
	thead {
		border-bottom-color: #dee2e6;
	}
	[class*='bg-']{
		color: $white;
	}
	.bg-light{
		color: $theme-body-font-color;
	}
	tfoot{
		font-weight:bold;
	}
}

.table-inverse {
	th,td{
		color: $white;
	}
}
.table[class*='bg-']{
	th,td{
		color: $white;
	}
}
.table-hover {
	> tbody {
		> tr {
			&:hover {
				--bs-table-accent-bg: #f6f6f6;
			}
		}
	}
}
.table-double {
	border-left: 4px double #dee2e6;
	border-right: 4px double #dee2e6;
	thead, tbody, tfoot, tr, td, th {
		border-style: double;
		border-width: 4px;
		border-right: 0;
		border-left: 0;
	}
}
.table-dotted {
	border-left: 2px dotted #dee2e6;
	border-right: 2px dotted #dee2e6;
	thead, tbody, tfoot, tr, td, th {
		border-style: dotted !important;
		border-width: 2px;
		border-right: 0;
		border-left: 0;
	}
}
.table-dashed {
	border-left: 2px dashed #dee2e6;
	border-right: 2px dashed #dee2e6;
	thead, tbody, tfoot, tr, td, th {
		border-style: dashed !important;
		border-width: 2px;
		border-right: 0;
		border-left: 0;
	}
}

//Sizing class
.table-xl{
	td,th{
		padding: 1.25rem 2rem;
		font-size: 120%;
	}
}
.table-lg{
	td,th{
		padding: .9rem 2rem;
		font-size: 110%;
	}
}
.table-de{
	td,th{
		padding: .75rem 2rem;
	}
}
.table-sm{
	th,td{
		padding: .5rem 2rem;
		font-size: 90%;
	}
}
.table-xs{
	th,td{
		padding: 0.2rem 2rem;
		font-size: 80%;
	}
}

//Table Border
.table-border-horizontal {
	tr,th,td{
		border-top: 1px solid #dee2e6;
		padding: .75rem 2rem;
	}
}
.table-border-vertical{
	tr,th,td{
		border-top: 0;
		border-right: 1px solid #dee2e6;
	}
	tr{
		td{
			border-bottom: 0;
			&:last-child{
				border-right: none;
			}
		}
	}
	tbody {
		tr {
			th {
				border-bottom: 0;
			}
		}
	}
}

.table-bordernone{
	td{
		border: 0;
	}
	.bd-t-none{
		border-top: none;
	}
	tbody {
		th {
			border-bottom: 0;
		}
	}
	.u-s-tb{
		padding: 11px;
	}
}

.table-inverse{
	color: $white;
	background-color: #292b2c;
	th,td{
		border-color: $white;
	}
	&.table-bordered {
		border: 0;
	}
}

.table-striped{
	tbody {
		tr
		{
			&:nth-of-type(odd){
				--bs-table-accent-bg: rgba(245, 247, 250, 0.5);
			}
		}
	}
}
.table-bordered{
	thead, tbody, tfoot, tr, td, th {
		border-color: $light;
	}
	thead {
		tr, th {
			border-bottom-color: #dee2e6;
		}
	}
}
.edit-profile{
	.table-responsive{
		padding: 10px 20px;
	}
}

/**=====================
     39.Table CSS Ends
==========================**/
