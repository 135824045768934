/**=====================
    40. Alertbox-call-out CSS start
==========================**/
.bs-callout-info {
    display: block;
    border-color: $primary-color !important;
    background-color: $primary-color;
    border-radius: 0.25rem;
    color: $black;
}
/**=====================
     40. Alertbox-call-out CSS End
==========================**/
