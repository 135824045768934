/**=====================
     51. Search CSS Start
==========================**/
.quickview-wrapper{
.border-tab.nav-primary .nav-item .nav-link.active{
  border: none !important;
  height: 0;
}
}
.search-page{
  .search-form{
    input{
      background-position:99%;
    }
  }
  .nav-link{
    font-weight: 500;
    text-transform: uppercase;
  }
  .info-block{
    + .info-block{
      padding-top: 30px;
      border-top: 1px solid $light-color;
      margin-top: 30px;
    }
  }

  .border-tab.nav-tabs{
    align-items: left;
    justify-content: end;
    .nav-item{
      width: auto;
    }
  }
  .search-links{
    h6{
      margin-bottom: 0;
    }
  }
  p{
    text-transform: lowercase;
    margin-bottom: 0;
    color: $gray-60;
  }
  ul.search-info{
    li{
      display: inline-block;
      font-size: 12px;
      line-height: 1;
      font-family: $font-open-sans;
      color: $light-gray;
      + li{
        border-left: 1px solid lighten($dark-color , 50%);
        padding-left: 8px;
        margin-left: 8px;
      }
      i{
        color: $warning-color;
      }
    }
  }
  #video-links{
    .embed-responsive
    + .embed-responsive{
      margin-top: 30px;
    }
  }
}
/**=====================
    51. Search CSS Ends
==========================**/