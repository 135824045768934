// :root {
//     --bs-table-striped-bg: rgba(245, 247, 250, 0.5) 
// }
// General variables
$theme-bg-color: #ffffff;
$theme-font-size:14px;
$theme-font-color: #444444;
$theme-body-font-color: #333333;
$theme-body-sub-title-color: #777777;

// Theme colors variables
$primary-color:#ab8ce4 ;
$secondary-color:#26c6da ;
$success-color: #00c292 ;
$info-color   :#4099ff ;
$warning-color: #f3d800;
$danger-color: #FF5370 ;
$light-color   :#eeeeee ;
$semi-dark  :#aaaaaa;
$light-semi-gray   :#ddd;
$light-gray   :#cccccc;
$dark-gray 	:#717171;
$dark-color   : #2a3142;
$gray-60   :#999999 ;
$transparent-color: transparent;
$auth-bg-color:#fafafa;
$light : #f6f6f6;

$dark-primary: #448aff;
$pitch-color: #fe8a7d;
$flower-pink: #fb6d9d;
$dark-sky: #536dfe;
$color-scarpa: #4c5667;
$color-fiord: #455a64;

// error page color add
$black : #000;
$white:#fff;
$btn-bhome-bg-c:#ff6060;
$btn-search-bg-c:#ffd042;
// error page color end

//social colors
$fb: #50598e;
$twitter: #6fa2d8;
$google-plus: #c64e40;
$linkedin: #0077B5;

//error input color start
$light-body-bg-color:#f7f7f7;

//fonts
$font-open-sans: Open Sans;
$font-raleway: Raleway;
$font-serif: sans-serif;
$font-arial: arial;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;

// dark layout variables
$dark-body-background: #303841;
$dark-card-background: #36404a;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgba(255,255,255,0.7);
$sidebar-submenu-font-color:#98a6ad;
$dark-border-color: #414d59;
$dark-border-color: #4b5469;
$dark-editor-document: #2b2b2b;

