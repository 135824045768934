/*-----------------------------------------------------------------------------------

    Template Name: Universal Admin
    Template URI: themes.pixelstrap.com/universal
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------

01. General CSS
  02. Generic CSS
  03. Card CSS
  04. Loader CSS
  05. Header CSS
  06. Button CSS
  07. Color CSS
  08. GRID CSS
  09. Error-page  CSS
  10. Icon css
  11. Form Builder 1 CSS
  12. Form Builder 2 CSS
   13.  Email Application  CSS
   14. Chat CSS
   15. Alert CSS
   16. Popover CSS
   17. List CSS
   18. Switch  CSS
   19. Mega option CSS
   20. Touchspin  CSS
    21. Select 2 CSS
	22. User profile css
	23. Progress CSS
	24. According CSS
	25. Authentication CSS
	26. Form CSS
	27. Coming Soon CSS
	28. Radio CSS
	29. Form Input CSS
	30. Data-table CSS
	 31. JS datagrid CSS
	 32. Page CSS
	 33. Ecommerce  CSS
	 34. Pricing CSS
	 35. Form builder css
	 36. NAV CSS
	 37. Dropdown CSS
	 38. Landing CSS
	 39.Table  CSS
	 40. Alertbox-call-out CSS
	 41. Chart CSS
	 42. Timeline CSS
	 43. Timeline 2 CSS
	 44. Tabs CSS
	 45. Gallery CSS
	 46. Blog CSS
	 47. Footer CSS
	 48. Form Wizard
	 49. Badge CSS
	 50. Tree CSS
	 51. Search CSS
	 52. Datepicker CSS
	 53. Dashboard CSS  Dashboard CSS
	 54. Tour  CSS
	 55. theme layout CSS
	 56. Ribbon CSS
	 57. breadcrumb CSS
	 58. Steps  CSS
	 59.Customizer CSS
	 60. RTL CSS
	 62. base_inputs CSS
	 63. Responsive css
 */


@import "theme/variables";
@import "theme/general";
@import "theme/generic";
@import "theme/card";
@import "theme/loader";
@import "theme/header-menu";
@import "theme/buttons";
@import "theme/color";
@import "theme/grid";
@import "theme/errorpage";
@import "theme/icons";
@import "theme/form-builder";
@import "theme/form_builder-2";
@import "theme/email-application";
@import "theme/chat";
@import "theme/alert";
@import "theme/popover";
@import "theme/list";
@import "theme/switch";
@import "theme/megaoption";
@import "theme/touchspin";
@import "theme/select2";
@import "theme/user-profile";
@import "theme/progress";
@import "theme/according";
@import "theme/login";
@import "theme/forms";
@import "theme/comingsoon";
@import "theme/radio";
@import "theme/form-input";
@import "theme/datatable";
@import "theme/jsgrid";
@import "theme/page";
@import "theme/ecommerce";
@import "theme/pricing";
@import "theme/builders";
@import "theme/navs";
@import "theme/dropdown";
@import "theme/landing";
@import "theme/table";
@import "theme/alertbox-callout";
@import "theme/chart";
@import "theme/timeline-v";
@import "theme/timeliny";
@import "theme/tab";
@import "theme/gallery";
@import "theme/blog";
@import "theme/footer";
@import "theme/form-wizard";
@import "theme/badge";
@import "theme/tree";
@import "theme/search";
@import "theme/datepicker";
@import "theme/dashboard";
@import "theme/tour";
@import "theme/custom-theme-options";
@import "theme/ribbon";
@import "theme/breadcrumb";
@import "theme/steps";
@import "theme/theme-customizer";
@import "theme/rtl";
@import "theme/modern-layout";
@import "theme/classic-layout";
@import "theme/fixed-nav-layout";
@import "theme/solitary-layout";
@import "theme/vertical-layout";