/**=====================
    33.  Ecommerce  CSS Start
==========================**/

/**====== Product Start ======**/
.credit-card{
  .text-center{
    img{
      margin-bottom: 20px;
    }
  }
}
.product-box{
  .product-details {
    padding: 25px;
    text-align: center;
    h6{
      color: $theme-body-font-color;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    span{
      color: $dark-gray;
    }
  }

  .product-img{
    position: relative;
    .product-hover{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.7);
      opacity: 0;
      border-radius: 100%;
      transform: scale(0);
      transition: all 0.3s ease;
      ul{
        li{
          display: inline-block;
          box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
          background-color: $white;
          font-size: 18px;
          border-radius: 5px;

          &:hover{
            background-color: $theme-body-font-color;
            color: $white;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
  &:hover{
    .product-hover{
    opacity: 1;
    border-radius: 0%;
    transform: scale(1);
    transition: all 0.3s ease;
    }
  }
}
.product-page-main{
  padding: 30px;
  .owl-item{
    .item{
      border: 1px solid $light-color;
      border-radius: 5px;
    }
    &.current{
      .item{
        border: 1px solid $primary-color;
      }
    }
  }
  .product-slider{
    margin-bottom: 20px;
  }
}
.product-page-details{
  h4{
    color: $theme-body-font-color;
    text-transform: uppercase;
    font-size: 18px;
  }
  span{
    padding-left: 15px;
  }
}
.br-theme-fontawesome-stars{
  .br-widget {
    a,a.br-selected:after,a.br-active:after {
      color: #ffa800;
      font: normal normal normal 14px/1 FontAwesome;
    }
  }
}
.product-price{
  font-size: 18px;
  font-weight: 700;
  font-family: $font-open-sans;
  del{
    color: $semi-dark;
    padding-right: 20px;
  }
}
.product-color{
  li{
    display: inline-block;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    margin: 0 2px;
  }
}
.product-page-main{
  p{
    font-size: 15px;
  }
  .nav-link{
    text-transform: uppercase;
    font-weight: 600;
  }
}
/**====== Product  Ends ======**/

.payment-opt{
  li{
    display: inline-block;
    img{
      height: 20px;
      margin: 15px 15px 0 0;
    }
  }
}

/**=====================
    33. Ecommerce  CSS End
==========================**/