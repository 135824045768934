/**=====================
   19. Mega option CSS Start
==========================**/
.mega-inline{
	margin-bottom: -30px;
	display: flex;
	.mega-title-badge{
		display:table;
		width: 100%;
		font-size: 14px;
    	font-weight: 600;
    	text-transform: uppercase;
	}
}
.mega-vertical{
	display: flex;
	flex-wrap: wrap;
	.mega-title-badge{
		display:table;
		width: 100%;
		font-size: 14px;
    	font-weight: 600;
    	text-transform: uppercase;
	}
}
.mega-title{
	font-weight: 600;
}
.mega-horizontal{
	display: flex;
	flex-wrap: wrap;
	.mega-title-badge{
		display:table;
		width: 100%;
		font-size: 14px;
    	font-weight: 600;
    	text-transform: uppercase;
	}
}
.plain-style{
	.card{
		box-shadow: none;
	}
}
.border-style{
	.card{
		box-shadow: none;
		border: 1px solid $light-gray;
	}
}
.offer-style{
	.card{
		box-shadow: none;
		border: 1px dashed $light-gray;
	}
}
.rating-star-wrapper{
	color: $light-semi-gray;
}
.mega-inline, .mega-vertical, .mega-horizontal {
	.radio {
		label {
			&::before {
				top: -10px;
			}
			&::after {
				top: -5px;
			}
		}
	}
}
/**=====================
     19. Mega option CSS Ends
==========================**/