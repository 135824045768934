//Fixed Navbar layout
.fixed-nav-layout {
  position: relative;
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-image: url("../images/maintence-bg.jpg");
  .container{
    max-width: unset;
    width: calc(100% - 400px);
  }
  .flot-chart-container{
    svg{
      filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.2));
    }
  }
  i,[class = "bottom-line-*"]{
    filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.3));
  }
  .sales-overview{
    svg{
      filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.3));
    }
  }
  &.page-wrapper{
    .page-body-wrapper {
      .page-body{
        background-color: rgba(246, 247, 251, 0.75);
      }
    }
  }

  .page-main-header{
    .main-header-left{
      padding: 10px;
      width: auto;
    }
  }
  .text-right{
    ul{
      li{
        text-align: center;
      }
      .list-inline-item:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .main-header-right {
    float: right;
    width: 76%;
    padding: 0;
  }
  .site-visiter-chart {
    ul{
      margin-bottom: 30px;
      li{
        width: 100px;
        text-align: center;
      }
    }
    .flot-chart-container {
      border: none;
      height: 350px;
      .ct-area {
        fill-opacity: 0;
      }
    }
    .bottom-line-info {
      border: 2px solid $info-color;
    }
    .bottom-line-primary {
      border: 2px solid $primary-color;
    }
    .bottom-line-secondary {
      border: 2px solid $secondary-color;
    }
    .bottom-line-danger {
      border: 2px solid $danger-color;
    }
  }
  .total-venders {
    .flot-chart-container {
      height: 215px;
      border: none;
      padding: 0;
      margin: 0;
    }
    .morris-hover.morris-default-style {
      display: none !important;
    }
  }
  .card .card-header {
    border-bottom: none;
    text-transform: uppercase;
    padding: 25px;
  }
  .sales-overview .chart-block canvas {
    height: 305px !important;
  }
  .social-widget {
    .media {
      padding: 30px;
    }
  }
  .crm-top-widget{
    .media{
      .media-body{
        h3{
          font-weight: 600;
          span{
            color:#333333;
          }
        }
      }
    }
  }
  .ecommerce-widget{
    .card-body{
      padding: 20px;
    }
    .flot-chart-container {
      height: 85px;
    }
  }
  .page-body-wrapper{
    &.sidebar-icon {
      .page-sidebar{
        height: 100%;
        left: 220px;
        width: 160px;
        ~ .page-body{
          margin-left: 160px;
          box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.12);
        }
        .sidebar-user{
          padding: 20px;
          background-color: $auth-bg-color;
          box-shadow: none;
          h6{
            letter-spacing: 1px;
            font-weight: 700;
          }
          div{
            background-color: #f5f5f5;
            width: 60px;
            margin: 0 auto;
            padding: 5px 0;
            border-radius: 50%;
            img{
              border: 3px solid #e6e6e6;
              box-shadow: none;
            }
          }
        }
        .sidebar-menu {
          max-height: 100%;
          overflow: auto;
          > li {
            .icon-sidebar{
              color: $theme-body-font-color;
            }
            .sub-header-title{
              font-size: 12px;
              color: $theme-body-font-color;
              font-weight: 600;
              margin-top: 5px;
            }
            &:last-child{
              .sidebar-header{
                border-bottom: none;
              }
            }
          }
          .sidebar-header{
            padding-top: 15px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #cacaca;
          }
        }
      }
      &.sidebar-close {
        .page-sidebar {
          width: 0;
          transition: 0.5s;
          opacity: 0;
        }
      }
    }
  }
  footer{
    bottom: -85px;
    padding: 30px;
    z-index: 999;
    .footer-copyright{
      p{
        font-size: 14px;
      }
    }
  }
  @media only screen and (max-width: 1500px){
      .container {
        width: calc(100% - 100px);
        max-width: unset;
      }
    .page-body-wrapper.sidebar-icon{
      .page-sidebar{
        left: 70px;
      }
    }
  }
  @media only screen and (max-width: 1199px){
    .container {
      width: calc(100% - 30px);
      max-width: unset;
    }
    .page-body-wrapper.sidebar-icon{
      .page-sidebar{
        left: 30px;
      }
    }
    .border-widgets {
      > {
        .row {
          > div{
            &:nth-child(2) {
              border-bottom: none;
            }
            &:nth-child(1){
              border-bottom: none;
            }
            &:nth-child(3){
              border-left: 1px solid #ddd;
            }
          }
        }
      }
    }
    .main-header-right{
      width: 73%;
    }
  }
  @media only screen and (max-width: 991px){
    .page-body-wrapper{
      &.sidebar-icon {
        .page-sidebar {
          left: 0;
        }
      }
      .page-sidebar{
        width: 0%;
        top: 60px;
        height: calc(100vh - 60px);
        ~ .page-body{
          margin-left:0px !important;
          padding-top: 0 !important;
        }
      }
      .page-sidebar-open{
        width: auto !important;
      }
    }
    .page-main-header{
      .main-header-left{
        padding: 0;
        .logo-wrapper{
          img {
            margin-top: 9px;
          }
        }
      }
      .main-header-right{
        width: 73%;
        .mobile-sidebar{
          margin-top: 13px;
        }
        .nav-right {
          > .mobile-toggle{
            padding-top: 21px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px){
    .site-visiter-chart {
      ul {
        li{
          width:80px;
        }
      }
    }
    .page-body-wrapper{
      .page-body{
        width: 100%;
        .user-status{
          min-width: 417px;
          overflow: auto;
        }
        .site-visiter-chart{
          ul{
            li{
              &.list-inline-item{
                &:last-child{
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }
    .page-main-header{
      .main-header-left{
        .logo-wrapper{
          img {
            margin-top: 11px;
          }
        }
      }
      .main-header-right{
        width: 66%;
      }
    }
  }
  @media only screen and (max-width: 575px){
    .site-visiter-chart{
      .flot-chart-container{
        height: 250px;
      }
    }
    .site-visiter-chart {
      ul {
        li {
          width: 45%;
          margin: 2% !important;
        }
      }
    }
    .page-main-header{
      .main-header-right {
        width: 50%;
      }
      .nav-right{
        > ul {
          .search-form{
            .mobile-search {
              background-image: url(../images/dashboard/search.png);
              height: 15px;
              width: 15px;
              display: inline-block;
              background-size: cover;
              margin: 5px 0 0;
            }
          }
        }
      }
    }
    .border-widgets {
      > .row {
        > div:nth-child(1) {
          border-bottom: 1px solid $light-semi-gray;
          .crm-top-widget{
            margin-top: 0;
          }
        }
        > div:nth-child(2) {
          border-bottom: 1px solid $light-semi-gray;
          border-left: 0;
        }
        > div:nth-child(3) {
          border-left: none;
          .crm-top-widget{
            margin-bottom: 0;
          }
        }
      }
    }
    .crm-top-widget{
      margin: 15px 0;
    }
    .page-body-wrapper {
      .page-body {
        .user-status {
          min-width: 314px;
          overflow: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 360px){
    .page-main-header {
      .main-header-right {
        .mobile-sidebar {
          margin-top: 6px;
        }
        .nav-right{
          > .mobile-toggle {
            padding-top: 13px;
          }
        }
      }
    }
    .border-widgets{
      > .row {
        > div:nth-child(3) {
          border-bottom: none;
        }
      }
    }
    .xs-col-100{
      max-width: 100%;
      flex:0 0 100%;
      &.text-right{
        text-align: center !important;
        margin-top: 15px;
      }
    }
    .page-body-wrapper {
      .page-body{
        .user-status {
          min-width:233px;
          overflow: auto;
        }
        .site-visiter-chart{
          ul{
            li{
              &.list-inline-item{
                width: 39%;
                margin-right: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}