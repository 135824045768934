/**=====================
     27. Coming Soon CSS Start
==========================**/
.comingsoon-bgimg{
  background: url(../images/coming-soon-bg.jpg);
  background-position: bottom;
  background-size:cover;
}
.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
  .comingsoon-inner {
    h5 {
      font-size: 18px;
      letter-spacing: 1px;
      color: $theme-body-sub-title-color;
      font-weight: 500;
      margin-bottom: 30px;
      margin-top:30px;
    }
    .countdown {
      padding: 30px 0;
      border-top:1px solid $light-color;
      border-bottom:1px solid $light-color;
      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: $white;
        font-weight: 500;
        width: 100px;
        height: 100px;
        font-size: 36px;
        background: $info-color;
      }
      .title {
        padding-top: 13px;
        font-size: 14px;
        font-weight: 400;
        color: $theme-body-sub-title-color;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      ul li {
        display: inline-block;
        text-transform: uppercase;
        margin: 0 20px;
        span {
          display: block;
        }
      }
    }
    .coming-soon-bottom-link{
      margin-top:30px;
      a{
        font-size: 18px;
        color: $black;
        text-decoration:none;
        font-weight: 600;
      }
    }
  }
  video{
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
  }
}
/**=====================
    27. Coming Soon CSS Ends
==========================**/